<template>
  <div class="bus-detail">
    <div class="detail-warp">
      <div class="logo-wrap">
        <img src="../../assets/qiwei.png" alt="">
      </div>
      <div class="details">
        <p class="detail-title">
          <span>企业微信</span>
        </p>
        <p class="describe">
          服务介绍：微信为企业打造的内部通讯管理工具。与微信一致的沟通体验，丰富的办公OA应用与API，精细化的管理配置，以及微信插件、企业支付等与微信连接的能力。让每个企业有自己的微信。
        </p>
        <div v-if="!form.relation">
          <span class="no-account">还没有企业微信？</span>
          <el-button type="text" @click="register">
            立即注册
          </el-button>
        </div>
        <div v-else>
          <el-button type="danger" plain>
            取消关联
          </el-button>
        </div>
        <div class="relation-wrap">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="关联设置" name="setting">
              <el-form ref="ruleForm" :model="form" :rules="rules" label-width="100px">
                <el-form-item label="CorpId" prop="corpId">
                  <el-input v-model="form.corpId" placeholder="请输入CorpId" :disabled="disabled" />
                </el-form-item>
                <el-form-item label="AgentId" prop="agentId">
                  <el-input v-model="form.agentId" placeholder="请输入AgentId" :disabled="disabled" />
                </el-form-item>
                <el-form-item label="AppSecret" prop="appSecret">
                  <el-input v-model="form.appSecret" placeholder="请输入AppSecret" :disabled="disabled" />
                </el-form-item>
                <el-form-item label="同步组织架构">
                  <el-switch
                    v-model="form.isSync"
                    :disabled="disabled"
                  />
                </el-form-item>
                <el-form-item>
                  <div v-if="!form.relation">
                    <el-button type="primary" @click="onSubmit('save')">
                      保存
                    </el-button>
                  </div>
                  <div v-else>
                    <div v-if="disabled">
                      <el-button type="primary" @click="onSubmit('edit')">
                        编辑
                      </el-button>
                      <el-button v-if="form.isSync" @click="onSubmit('sync')">
                        一键同步
                      </el-button>
                    </div>
                    <div v-else>
                      <el-button @click="onSubmit('cancle')">
                        取消
                      </el-button>
                      <el-button type="primary" @click="onSubmit('save')">
                        保存
                      </el-button>
                    </div>
                  </div>
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="关联信息" name="info">
              <div class="info-wrap">
                <p class="core-title">
                  基础关联信息
                </p>
                <p class="item">
                  公司名称：<span class="info">{{ form.company || '无' }}</span>
                </p>
                <p class="item">
                  同步时间：<span class="info">{{ form.syncTime || '无' }}</span>
                </p>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { syncDetail, syncInfo, bdIntegration } from '@/api/setting';
export default {
  data () {
    return {
      list: [
        { label: '企业微信1' },
        { label: '企业微信2' },
        { label: '企业微信3' }
      ],
      activeName: 'setting',
      rules: {
        corpId: [
          { required: true, message: '请输入CorpId', trigger: 'blur' },
        ],
        agentId: [
          { required: true, message: '请输入AgentId', trigger: 'blur' },
        ],
        appSecret: [
          { required: true, message: '请输入AppSecret', trigger: 'blur' },
        ],
      },
      form: {
        corpId: '',
        agentId: '',
        appSecret: '',
        company: '',
        syncTime: '',
        isSync: 0,
        relation: null,
      },
      type: {
        'ACCOUNT': 'self',
        'WECHAT': 'qiwei'
      },
      disabled: false,
    };
  },
  mounted () {
    this.init();
  },
  methods: {
    async init () {
      const { appId, sns } = this.$route.query;
      const res = await syncDetail({ appId, sns });
      this.form = res;
      this.form.isSync = res.isSync ? 1 : 0;
      this.disabled = res.relation ? true : false;
    },
    handleClick () { },
    onSubmit (key) {
      switch (key) {
        case 'edit':
          this.disabled = false;
          break;
        case 'cancle':
          this.disabled = true;
          break;
        case 'save':
          this.bdIntegration();
          break;
        case 'sync':
          this.syncInfo();
          break;
      }
    },
    // 保存
    bdIntegration () {
      this.$refs['ruleForm'].validate(async (valid) => {
        if (valid) {
          const { agentId, appSecret, corpId, intelId, isSync, relation } = this.form;
          const issSync = Number(isSync);
          const { appId, sns } = this.$route.query;
          const params = {
            agentId, appId, appSecret, corpId, intelId, isSync: issSync, relation, sns
          };
          const res = await bdIntegration(params);
          console.log('保存', res);
        } else {
          return false;
        }
      });
    },
    // 一键同步
    async syncInfo () {
      const { appId, sns } = this.$route.query;
      let type;
      switch (sns) {
        case 'ACCOUNT':
          type = 'self';
          break;
        case 'WECHAT':
          type = 'qiwei';
          break;
      }
      const params = { appId };
      const res = await syncInfo(type, params);
      console.log('一键同步', res);
    },
    register () {
      window.open('https://work.weixin.qq.com/');
    },
  },
};
</script>
<style scoped lang='scss'>
.bus-detail {
  padding: 0px 24px 24px;
  min-height: calc(100vh - 143px);
  background: rgb(255, 255, 255);
  .detail-warp {
    display: flex;
    padding-top: 40px;
    width: 100%;
    .logo-wrap {
      flex: 0 0 110px;
      height: 110px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-right: 24px;
      border: 1px solid #dee0e3;
      box-sizing: border-box;
      border-radius: 4px;
    }
    .details {
      flex: 1;
      .detail-title {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        color: #1f2329;
        font-weight: 600;
      }
      .describe {
        font-size: 14px;
        color: #8f959e;
        line-height: 21px;
      }
    }
    .relation-wrap {
      margin-top: 10px;
      .info-wrap {
        margin-top: 9px;
        .core-title {
          font-size: 14px;
          color: #1f2329;
          font-weight: 600;
          margin-bottom: 24px;
        }
        .item {
          font-size: 14px;
          color: #1f2329;
          margin-bottom: 20px;
        }
      }
    }
    .no-account {
      font-size: 12px;
      color: #8f959e;
    }
  }
  .el-input {
    width: 360px;
    &.min-input {
      width: 120px;
    }
  }
}
</style>
<style lang='scss'>
.bus-detail {
  .el-tabs__content {
    margin-top: 24px;
  }
}
</style>