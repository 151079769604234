import { render, staticRenderFns } from "./bus-detail.vue?vue&type=template&id=775060ac&scoped=true&"
import script from "./bus-detail.vue?vue&type=script&lang=js&"
export * from "./bus-detail.vue?vue&type=script&lang=js&"
import style0 from "./bus-detail.vue?vue&type=style&index=0&id=775060ac&scoped=true&lang=scss&"
import style1 from "./bus-detail.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "775060ac",
  null
  
)

export default component.exports